import React from "react"

export default function Logo({ className }) {
  return (
    <svg
      version="1.1"
      className="w-full h-full"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 380 118.1"
      enableBackground="new 0 0 380 118.1"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill="#fff"
          d="M87.3,39.7c0.2,12.7,8.3,18,17.6,18c6.7,0,10.7-1.2,14.2-2.7l1.6,6.7c-3.3,1.5-8.9,3.2-17.1,3.2
		c-15.8,0-25.2-10.5-25.2-26.1c0-15.6,9.1-27.9,24.1-27.9c16.7,0,21.2,14.9,21.2,24.4c0,1.9-0.2,3.4-0.3,4.4H87.3z M114.7,33
		c0.1-6-2.4-15.3-12.9-15.3c-9.4,0-13.6,8.8-14.3,15.3H114.7z"
        />
        <path
          fill="#fff"
          d="M181.7,37.6c0,19.1-13.1,27.5-25.5,27.5c-13.9,0-24.6-10.3-24.6-26.6c0-17.3,11.2-27.5,25.4-27.5
		C171.7,11,181.7,21.8,181.7,37.6z M141,38.1c0,11.3,6.5,19.9,15.6,19.9c8.9,0,15.6-8.4,15.6-20.1c0-8.8-4.3-19.9-15.4-19.9
		S141,28.3,141,38.1z"
        />
        <path
          fill="#fff"
          d="M237.6,12.1c-0.2,3.7-0.4,7.9-0.4,14.2v30.1c0,11.9-2.3,19.1-7.3,23.6c-5,4.7-12.2,6.2-18.6,6.2
		c-6.1,0-12.9-1.5-17.1-4.3l2.3-7.2c3.4,2.1,8.7,4.1,15,4.1c9.5,0,16.5-5,16.5-18.1V55h-0.2c-2.9,4.8-8.4,8.7-16.3,8.7
		c-12.7,0-21.8-10.9-21.8-25.2c0-17.5,11.3-27.5,23.1-27.5c8.9,0,13.8,4.7,16,9h0.2l0.4-7.8H237.6z M228,32.6c0-1.6-0.1-3-0.5-4.3
		c-1.7-5.5-6.3-9.9-13-9.9c-8.9,0-15.3,7.6-15.3,19.6c0,10.2,5.1,18.6,15.2,18.6c5.7,0,10.9-3.6,12.9-9.6c0.5-1.6,0.7-3.4,0.7-5
		V32.6z"
        />
        <path
          fill="#fff"
          d="M281,63.9l-0.7-6.5H280c-2.9,4.1-8.4,7.7-15.7,7.7c-10.4,0-15.7-7.4-15.7-14.9c0-12.5,11-19.4,30.8-19.3
		v-1.1c0-4.3-1.2-12-11.7-12c-4.8,0-9.7,1.5-13.4,3.8l-2.1-6.2c4.2-2.8,10.4-4.6,16.8-4.6c15.7,0,19.5,10.8,19.5,21.2v19.4
		c0,4.5,0.2,8.9,0.8,12.4H281z M279.7,37.5c-10.2-0.2-21.7,1.6-21.7,11.7c0,6.1,4,9,8.8,9c6.7,0,10.9-4.3,12.4-8.7
		c0.3-1,0.5-2,0.5-3V37.5z"
        />
      </g>
      <path
        fill="#fff"
        d="M9.4,106.6c3.9,1.4,11.1,6.4,11.6,6.4c0.1,0.2-0.3,0.9-0.3,0.9s0.6,1.1,0.3,1.1s-19.7-1.2-19.7-1.2
	S0,112.5,0,109.8c3.7-8.6,6.2-17,6.2-17s1.5-7.6,4.1-11.1c0,0,4.8-4.5,8-8.6c3.2-4.2,6.7-21.2,6.7-21.2s3.6-16.1,15.3-18.5
	C51.8,31,60.8,53.6,67.3,57.8c4.4,3.9,10.6,8.9,12.3,12.6c1.7,3.7-0.6,5.5,1,7.1c1.5,1.6,13.2,13.4,15,15.3
	c1.8,1.9,5.9,8.4,8.9,11.3c3,3,16.7,5.2,14.5,8.4c-1,1.4,0,1.7,0,1.7l-6.7-2c0,0-0.3,2.3-1.7,1.2c-1.4-1.1-3-1-3-1s0.7,1-2.5,0.2
	c-3.2-0.7-5.4-7.4-11.8-9.9C89.6,100.3,85,97,85,97s-3,6.7-8.9,5.7c-10.3-1-9.6-8.9-9.6-8.9s0.8-1.2-0.1-1c-0.7,0.1,0.2-1.2,0-1.4
	c-0.7-0.8-0.7-0.8-0.7-0.8s-0.7-5.8,0.5-6.4c1.2-0.6,3.7-2,3.7-2c-0.2-1.7-20.8-18.5-20.8-18.5s-5.1-5.9-7.3-4.9
	c-2.2,1-12.6,17-15.3,19.5c-2.7,2.5-5.7,7-9.1,13.1C14.1,97.4,8.9,103.6,9.4,106.6z"
      />
      <polygon
        fill="#fff"
        points="26.7,46.7 26.7,1.3 35.7,1.3 35.7,35.3 63.1,1.3 74.4,1.3 45.1,36.5 43.3,45 "
      />
      <g>
        <g>
          <path
            fill="#fff"
            d="M364.9,40.9c-0.3-2.4,1.4-8.1,3.1-13.1c1.7-5,0.3-10.3-3.3-15.6c-3.6-5.3-9.6-6.9-19.1-7.4
			c-9.4-0.5-18,0.9-21.8,3.3c-3.8,2.4-9.3,7.6-9.3,13.4c0,5.8,1.6,11.9,2.6,16.5c-1.1,4.8-6.1,9.2-5.9,19.9
			c0.2,13.4,5.5,18.5,11,23.4c5.5,4.8,12.7,5,17.2,5s13.1-0.9,17-3.8c3.9-2.9,8.8-6.4,12.2-18.4C372.2,52.1,365.3,43.3,364.9,40.9z
			 M356.2,29.7c-2.1,1-10.7-1-14.5-0.9c-3.9,0.2-8.5,1.3-11.2,1.1c-2.7-0.1-5-2.2-4.8-6.3c0.2-4.1,2.2-5.7,2.2-5.7s4.4-3.1,13.8-2.9
			c9.4,0.2,13.3,1.4,15.4,4.5C359.1,22.7,358.2,28.7,356.2,29.7z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#D85319"
          d="M326.7,69.9V57.4h-2v-2h2v-0.7c0-2,0.4-3.9,1.7-5c1-1,2.3-1.3,3.5-1.3c0.9,0,1.7,0.2,2.2,0.4l-0.4,2
		c-0.4-0.2-0.9-0.3-1.7-0.3c-2.2,0-2.8,2-2.8,4.2v0.8h3.5v2h-3.5v12.5H326.7z"
        />
        <path
          fill="#D85319"
          d="M342.5,51.4c0,0.9-0.6,1.6-1.7,1.6c-0.9,0-1.6-0.7-1.6-1.6c0-0.9,0.7-1.6,1.6-1.6
		C341.9,49.7,342.5,50.4,342.5,51.4z M339.6,69.9V55.4h2.6v14.5H339.6z"
        />
        <path
          fill="#D85319"
          d="M354.2,51.3v4.2h3.8v2h-3.8v7.8c0,1.8,0.5,2.8,2,2.8c0.7,0,1.2-0.1,1.5-0.2l0.1,2c-0.5,0.2-1.3,0.4-2.3,0.4
		c-1.2,0-2.2-0.4-2.8-1.1c-0.7-0.8-1-2.1-1-3.8v-7.9h-2.2v-2h2.2V52L354.2,51.3z"
        />
      </g>
      <g>
        <path
          fill="#fff"
          d="M140.8,110.8c-0.1-1.3-0.2-2.9-0.1-4.1h0c-0.3,1.1-0.7,2.3-1.2,3.6l-1.7,4.6h-0.9l-1.5-4.5
		c-0.4-1.3-0.8-2.5-1.1-3.7h0c0,1.2-0.1,2.8-0.2,4.2l-0.3,4h-1.2l0.7-9.4h1.6l1.6,4.6c0.4,1.2,0.7,2.2,1,3.2h0c0.2-1,0.6-2,1-3.2
		l1.7-4.6h1.6l0.6,9.4H141L140.8,110.8z"
        />
        <path
          fill="#fff"
          d="M145.6,106.3c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.7-0.3-0.7-0.8c0-0.4,0.3-0.8,0.8-0.8
		C145.3,105.5,145.6,105.9,145.6,106.3z M144.2,115v-6.8h1.2v6.8H144.2z"
        />
        <path
          fill="#fff"
          d="M147.5,110c0-0.7,0-1.3-0.1-1.8h1.1l0.1,1.1h0c0.3-0.6,1.1-1.3,2.2-1.3c0.9,0,2.4,0.6,2.4,2.9v4H152v-3.9
		c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.7,1.3c-0.1,0.2-0.1,0.4-0.1,0.6v4.1h-1.2V110z"
        />
        <path
          fill="#fff"
          d="M161.1,105v8.2c0,0.6,0,1.3,0.1,1.8h-1.1l-0.1-1.2h0c-0.4,0.8-1.2,1.3-2.3,1.3c-1.6,0-2.9-1.4-2.9-3.4
		c0-2.3,1.4-3.6,3-3.6c1,0,1.7,0.5,2,1h0v-4H161.1z M159.9,111c0-0.2,0-0.4-0.1-0.5c-0.2-0.8-0.9-1.4-1.8-1.4c-1.3,0-2,1.1-2,2.6
		c0,1.4,0.7,2.5,2,2.5c0.8,0,1.6-0.5,1.8-1.5c0-0.2,0.1-0.3,0.1-0.5V111z"
        />
        <path
          fill="#fff"
          d="M163.3,115v-5.8h-1v-0.9h1v-0.3c0-1,0.2-1.8,0.8-2.4c0.5-0.4,1.1-0.6,1.7-0.6c0.4,0,0.8,0.1,1.1,0.2l-0.2,1
		c-0.2-0.1-0.4-0.2-0.8-0.2c-1.1,0-1.3,0.9-1.3,2v0.4h1.6v0.9h-1.6v5.8H163.3z"
        />
        <path
          fill="#fff"
          d="M172.9,113.1c0,0.7,0,1.3,0.1,1.8h-1.1l-0.1-1.1h0c-0.3,0.5-1,1.3-2.2,1.3c-1.1,0-2.3-0.6-2.3-3v-4h1.2v3.8
		c0,1.3,0.4,2.2,1.5,2.2c0.8,0,1.4-0.6,1.6-1.1c0.1-0.2,0.1-0.4,0.1-0.6v-4.2h1.2V113.1z"
        />
        <path fill="#fff" d="M175,105h1.2v9.9H175V105z" />
        <path fill="#fff" d="M178.3,105h1.2v9.9h-1.2V105z" />
        <path
          fill="#fff"
          d="M182,108.2l1.5,4c0.2,0.4,0.3,1,0.4,1.4h0c0.1-0.4,0.3-0.9,0.4-1.4l1.3-4h1.3l-1.8,4.8
		c-0.9,2.3-1.5,3.5-2.3,4.2c-0.6,0.5-1.2,0.7-1.5,0.8l-0.3-1c0.3-0.1,0.7-0.3,1.1-0.6c0.3-0.3,0.8-0.7,1-1.4
		c0.1-0.1,0.1-0.2,0.1-0.3s0-0.2-0.1-0.3l-2.5-6.2H182z"
        />
        <path
          fill="#fff"
          d="M191,113.5c0.5,0.3,1.3,0.6,2.2,0.6c1.2,0,2-0.7,2-1.6c0-0.9-0.5-1.4-1.8-1.9c-1.5-0.5-2.5-1.3-2.5-2.7
		c0-1.5,1.2-2.6,3.1-2.6c1,0,1.7,0.2,2.1,0.5l-0.3,1c-0.3-0.2-0.9-0.4-1.8-0.4c-1.3,0-1.8,0.8-1.8,1.4c0,0.9,0.6,1.3,1.9,1.8
		c1.6,0.6,2.4,1.4,2.4,2.8c0,1.5-1.1,2.7-3.3,2.7c-0.9,0-1.9-0.3-2.4-0.6L191,113.5z"
        />
        <path
          fill="#fff"
          d="M199.5,106.3v1.9h1.8v0.9h-1.8v3.7c0,0.8,0.2,1.3,0.9,1.3c0.3,0,0.6,0,0.7-0.1l0.1,0.9
		c-0.2,0.1-0.6,0.2-1.1,0.2c-0.6,0-1-0.2-1.3-0.5c-0.4-0.4-0.5-1-0.5-1.8v-3.7h-1.1v-0.9h1.1v-1.6L199.5,106.3z"
        />
        <path
          fill="#fff"
          d="M202.6,110.3c0-0.8,0-1.5-0.1-2.1h1.1l0,1.3h0.1c0.3-0.9,1.1-1.5,1.9-1.5c0.1,0,0.2,0,0.3,0v1.2
		c-0.1,0-0.3,0-0.4,0c-0.9,0-1.5,0.7-1.7,1.6c0,0.2-0.1,0.4-0.1,0.6v3.6h-1.2V110.3z"
        />
        <path
          fill="#fff"
          d="M207.8,111.8c0,1.7,1.1,2.4,2.3,2.4c0.9,0,1.4-0.2,1.9-0.4l0.2,0.9c-0.4,0.2-1.2,0.4-2.3,0.4
		c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.7,3.2-3.7c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H207.8z M211.4,110.9c0-0.8-0.3-2-1.7-2
		c-1.2,0-1.8,1.1-1.9,2H211.4z"
        />
        <path
          fill="#fff"
          d="M214.1,110c0-0.7,0-1.3-0.1-1.8h1.1l0.1,1.1h0c0.3-0.6,1.1-1.3,2.2-1.3c0.9,0,2.4,0.6,2.4,2.9v4h-1.2v-3.9
		c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.7,1.3c-0.1,0.2-0.1,0.4-0.1,0.6v4.1h-1.2V110z"
        />
        <path
          fill="#fff"
          d="M227.7,108.2c0,0.5-0.1,1-0.1,1.9v3.9c0,1.6-0.3,2.5-1,3.1c-0.7,0.6-1.6,0.8-2.5,0.8
		c-0.8,0-1.7-0.2-2.3-0.6l0.3-0.9c0.4,0.3,1.1,0.5,2,0.5c1.3,0,2.2-0.7,2.2-2.4v-0.8h0c-0.4,0.6-1.1,1.1-2.2,1.1
		c-1.7,0-2.9-1.4-2.9-3.3c0-2.3,1.5-3.6,3.1-3.6c1.2,0,1.8,0.6,2.1,1.2h0l0.1-1H227.7z M226.4,110.9c0-0.2,0-0.4-0.1-0.6
		c-0.2-0.7-0.8-1.3-1.7-1.3c-1.2,0-2,1-2,2.6c0,1.3,0.7,2.4,2,2.4c0.8,0,1.4-0.5,1.7-1.3c0.1-0.2,0.1-0.4,0.1-0.7V110.9z"
        />
        <path
          fill="#fff"
          d="M231.2,106.3v1.9h1.8v0.9h-1.8v3.7c0,0.8,0.2,1.3,0.9,1.3c0.3,0,0.6,0,0.7-0.1l0.1,0.9
		c-0.2,0.1-0.6,0.2-1.1,0.2c-0.6,0-1-0.2-1.3-0.5c-0.4-0.4-0.5-1-0.5-1.8v-3.7h-1.1v-0.9h1.1v-1.6L231.2,106.3z"
        />
        <path
          fill="#fff"
          d="M234.3,105h1.2v4.2h0c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.3-0.3c0.9,0,2.4,0.6,2.4,2.9v4h-1.2v-3.9
		c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.7,1.2c-0.1,0.2-0.1,0.4-0.1,0.6v4.1h-1.2V105z"
        />
        <path
          fill="#fff"
          d="M242.8,111.8c0,1.7,1.1,2.4,2.3,2.4c0.9,0,1.4-0.2,1.9-0.4l0.2,0.9c-0.4,0.2-1.2,0.4-2.3,0.4
		c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.7,3.2-3.7c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H242.8z M246.4,110.9c0-0.8-0.3-2-1.7-2
		c-1.2,0-1.8,1.1-1.9,2H246.4z"
        />
        <path
          fill="#fff"
          d="M249.1,110c0-0.7,0-1.3-0.1-1.8h1.1l0.1,1.1h0c0.3-0.6,1.1-1.3,2.2-1.3c0.9,0,2.4,0.6,2.4,2.9v4h-1.2v-3.9
		c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.7,1.3c-0.1,0.2-0.1,0.4-0.1,0.6v4.1h-1.2V110z"
        />
        <path fill="#fff" d="M259.9,105.5h1.2v8.4h4v1h-5.3V105.5z" />
        <path
          fill="#fff"
          d="M266.9,111.8c0,1.7,1.1,2.4,2.3,2.4c0.9,0,1.4-0.2,1.9-0.4l0.2,0.9c-0.4,0.2-1.2,0.4-2.3,0.4
		c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.7,3.2-3.7c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H266.9z M270.5,110.9c0-0.8-0.3-2-1.7-2
		c-1.2,0-1.8,1.1-1.9,2H270.5z"
        />
        <path
          fill="#fff"
          d="M273.3,110c0-0.7,0-1.3-0.1-1.8h1.1l0.1,1.1h0c0.3-0.6,1.1-1.3,2.2-1.3c0.9,0,2.4,0.6,2.4,2.9v4h-1.2v-3.9
		c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.7,1.3c-0.1,0.2-0.1,0.4-0.1,0.6v4.1h-1.2V110z"
        />
        <path
          fill="#fff"
          d="M286.9,108.2c0,0.5-0.1,1-0.1,1.9v3.9c0,1.6-0.3,2.5-1,3.1c-0.7,0.6-1.6,0.8-2.5,0.8
		c-0.8,0-1.7-0.2-2.3-0.6l0.3-0.9c0.4,0.3,1.1,0.5,2,0.5c1.3,0,2.2-0.7,2.2-2.4v-0.8h0c-0.4,0.6-1.1,1.1-2.2,1.1
		c-1.7,0-2.9-1.4-2.9-3.3c0-2.3,1.5-3.6,3.1-3.6c1.2,0,1.8,0.6,2.1,1.2h0l0.1-1H286.9z M285.6,110.9c0-0.2,0-0.4-0.1-0.6
		c-0.2-0.7-0.8-1.3-1.7-1.3c-1.2,0-2,1-2,2.6c0,1.3,0.7,2.4,2,2.4c0.8,0,1.4-0.5,1.7-1.3c0.1-0.2,0.1-0.4,0.1-0.7V110.9z"
        />
        <path
          fill="#fff"
          d="M290.3,106.3v1.9h1.8v0.9h-1.8v3.7c0,0.8,0.2,1.3,0.9,1.3c0.3,0,0.6,0,0.7-0.1l0.1,0.9
		c-0.2,0.1-0.6,0.2-1.1,0.2c-0.6,0-1-0.2-1.3-0.5c-0.4-0.4-0.5-1-0.5-1.8v-3.7h-1.1v-0.9h1.1v-1.6L290.3,106.3z"
        />
        <path
          fill="#fff"
          d="M293.5,105h1.2v4.2h0c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.8-0.3,1.3-0.3c0.9,0,2.4,0.6,2.4,2.9v4H298v-3.9
		c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.7,1.2c-0.1,0.2-0.1,0.4-0.1,0.6v4.1h-1.2V105z"
        />
        <path
          fill="#fff"
          d="M301.9,111.8c0,1.7,1.1,2.4,2.3,2.4c0.9,0,1.4-0.2,1.9-0.4l0.2,0.9c-0.4,0.2-1.2,0.4-2.3,0.4
		c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.7,3.2-3.7c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H301.9z M305.6,110.9c0-0.8-0.3-2-1.7-2
		c-1.2,0-1.8,1.1-1.9,2H305.6z"
        />
        <path
          fill="#fff"
          d="M308.3,110c0-0.7,0-1.3-0.1-1.8h1.1l0.1,1.1h0c0.3-0.6,1.1-1.3,2.2-1.3c0.9,0,2.4,0.6,2.4,2.9v4h-1.2v-3.9
		c0-1.1-0.4-2-1.6-2c-0.8,0-1.4,0.6-1.7,1.3c-0.1,0.2-0.1,0.4-0.1,0.6v4.1h-1.2V110z"
        />
        <path
          fill="#fff"
          d="M318.9,113.5c0.5,0.3,1.3,0.6,2.2,0.6c1.2,0,2-0.7,2-1.6c0-0.9-0.5-1.4-1.8-1.9c-1.5-0.5-2.5-1.3-2.5-2.7
		c0-1.5,1.2-2.6,3.1-2.6c1,0,1.7,0.2,2.1,0.5l-0.3,1c-0.3-0.2-0.9-0.4-1.8-0.4c-1.3,0-1.8,0.8-1.8,1.4c0,0.9,0.6,1.3,1.9,1.8
		c1.6,0.6,2.4,1.4,2.4,2.8c0,1.5-1.1,2.7-3.3,2.7c-0.9,0-1.9-0.3-2.4-0.6L318.9,113.5z"
        />
        <path
          fill="#fff"
          d="M327.4,106.3v1.9h1.8v0.9h-1.8v3.7c0,0.8,0.2,1.3,0.9,1.3c0.3,0,0.6,0,0.7-0.1l0.1,0.9
		c-0.2,0.1-0.6,0.2-1.1,0.2c-0.6,0-1-0.2-1.3-0.5c-0.4-0.4-0.5-1-0.5-1.8v-3.7h-1.1v-0.9h1.1v-1.6L327.4,106.3z"
        />
        <path
          fill="#fff"
          d="M334.3,115l-0.1-0.9h0c-0.4,0.5-1.1,1-2.1,1c-1.4,0-2.1-1-2.1-1.9c0-1.6,1.5-2.5,4.1-2.5v-0.1
		c0-0.6-0.2-1.6-1.5-1.6c-0.6,0-1.3,0.2-1.8,0.5l-0.3-0.8c0.6-0.4,1.4-0.6,2.2-0.6c2.1,0,2.6,1.4,2.6,2.8v2.5c0,0.6,0,1.2,0.1,1.6
		H334.3z M334.1,111.5c-1.3,0-2.9,0.2-2.9,1.5c0,0.8,0.5,1.2,1.2,1.2c0.9,0,1.4-0.6,1.6-1.1c0-0.1,0.1-0.3,0.1-0.4V111.5z"
        />
        <path
          fill="#fff"
          d="M337.2,115c0-0.5,0.1-1.1,0.1-1.8V105h1.2v4.3h0c0.4-0.8,1.2-1.2,2.3-1.2c1.7,0,2.9,1.4,2.9,3.5
		c0,2.4-1.5,3.6-3,3.6c-1,0-1.8-0.4-2.3-1.3h0l-0.1,1.1H337.2z M338.5,112.3c0,0.2,0,0.3,0.1,0.4c0.2,0.9,1,1.4,1.8,1.4
		c1.3,0,2.1-1.1,2.1-2.6c0-1.4-0.7-2.5-2-2.5c-0.8,0-1.6,0.6-1.9,1.5c0,0.1-0.1,0.3-0.1,0.5V112.3z"
        />
        <path
          fill="#fff"
          d="M346.6,106.3c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.7-0.3-0.7-0.8c0-0.4,0.3-0.8,0.8-0.8
		C346.3,105.5,346.6,105.9,346.6,106.3z M345.2,115v-6.8h1.2v6.8H345.2z"
        />
        <path fill="#fff" d="M348.5,105h1.2v9.9h-1.2V105z" />
        <path
          fill="#fff"
          d="M353.2,106.3c0,0.4-0.3,0.8-0.8,0.8c-0.4,0-0.7-0.3-0.7-0.8c0-0.4,0.3-0.8,0.8-0.8
		C352.9,105.5,353.2,105.9,353.2,106.3z M351.8,115v-6.8h1.2v6.8H351.8z"
        />
        <path
          fill="#fff"
          d="M354.9,113.7c0.4,0.2,1,0.5,1.6,0.5c0.9,0,1.3-0.4,1.3-1c0-0.6-0.3-0.9-1.3-1.2c-1.2-0.4-1.8-1.1-1.8-1.9
		c0-1.1,0.9-2,2.3-2c0.7,0,1.3,0.2,1.7,0.4l-0.3,0.9c-0.3-0.2-0.8-0.4-1.4-0.4c-0.7,0-1.1,0.4-1.1,0.9c0,0.6,0.4,0.8,1.3,1.1
		c1.2,0.4,1.8,1,1.8,2c0,1.2-0.9,2-2.5,2c-0.7,0-1.4-0.2-1.9-0.5L354.9,113.7z"
        />
        <path
          fill="#fff"
          d="M361.3,111.8c0,1.7,1.1,2.4,2.3,2.4c0.9,0,1.4-0.2,1.9-0.4l0.2,0.9c-0.4,0.2-1.2,0.4-2.3,0.4
		c-2.1,0-3.3-1.4-3.3-3.4c0-2,1.2-3.7,3.2-3.7c2.2,0,2.8,1.9,2.8,3.2c0,0.3,0,0.4,0,0.6H361.3z M364.9,110.9c0-0.8-0.3-2-1.7-2
		c-1.2,0-1.8,1.1-1.9,2H364.9z"
        />
      </g>
      <g>
        <path
          fill="#fff"
          d="M380,2.6c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7c0-1.5,1.2-2.6,2.7-2.6C378.8,0,380,1.2,380,2.6z
		 M375.2,2.6c0,1.2,0.9,2.1,2,2.1c1.2,0,2-0.9,2-2.1c0-1.2-0.8-2.1-2-2.1C376.1,0.5,375.2,1.5,375.2,2.6z M376.9,4h-0.6V1.4
		c0.2,0,0.6-0.1,1-0.1c0.5,0,0.7,0.1,0.9,0.2c0.1,0.1,0.3,0.3,0.3,0.6c0,0.3-0.2,0.5-0.5,0.6v0c0.3,0.1,0.4,0.3,0.5,0.6
		c0.1,0.4,0.1,0.6,0.2,0.7h-0.7c-0.1-0.1-0.1-0.3-0.2-0.6c0-0.3-0.2-0.4-0.5-0.4h-0.3V4z M376.9,2.5h0.3c0.3,0,0.6-0.1,0.6-0.4
		c0-0.2-0.2-0.4-0.6-0.4c-0.2,0-0.3,0-0.3,0V2.5z"
        />
      </g>
    </svg>
  )
}
